@use "./scss/colors" as *;

body {
  background-color: $background-charcoal-400;
}

.root {
  min-height: 100vh;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
}

header {
  background-color: $background-charcoal-600;
  display: flex;
  justify-content: center;
}

.main-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  max-width: 920px;
  padding: 20px 0;
  text-align: center;
  width: 100%;

  .logo {
    a {
      color: white;
      font-weight: 600;
      text-decoration: none;
      white-space: nowrap;

      :first-child {
        display: block;
        font-size: 3rem;
      }
    }
  }

  .nav {
    display: flex;
    gap: 15px;
    align-items: center;

    a {
      color: $text-gray-100;
      font-weight: 800;
      letter-spacing: 2px;
      padding: 20px 15px;
      position: relative;
      text-decoration: none;
      text-transform: uppercase;

      &:after,
      &:before {
        transition: all 0.5s;
      }

      &:hover,
      &.active {
        color: $text-gray-100;

        &:after {
          width: 100%;
        }
      }

      &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 0%;
        content: ".";
        color: transparent;
        background: $background-red-300;
        height: 1px;
      }
    }
  }
}

main {
  display: flex;
  justify-content: center;
}

.main-content {
  display: flex;
  justify-content: space-between;
  max-width: 920px;
  width: 100%;

  .social-content {
    width: 40px;
    position: fixed;
    bottom: 0px;
    left: 40px;
    right: auto;
    z-index: 10;
    color: $background-red-300;
  }
}

.right-content {
  min-width: 40px;
}

.title {
  text-shadow: 0px 4px 4px $text-gray-400;
}

.spacer {
  flex-grow: 1;
}

@media screen and (max-width: 960px) {
  .main-header {
    padding: 20px 30px;
  }

  .main-content {
    padding: 0 30px;
  }

  .panel-border-left p {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 630px) {
  h1 {
    font-size: 2rem !important;
  }

  .main-header .nav {
    justify-content: center;
  }

  .main-header {
    flex-direction: column;
    min-width: 380px;
  }

  .main-content {
    min-width: 350px;
  }

  .right-content {
    min-width: 0;
  }

  .panel-border-left p {
    font-size: 1rem;
  }

  .social {
    margin-top: 64px;
  }
}
