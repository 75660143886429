@use "../../scss/colors.scss" as *;

.social {
  display: flex;
  flex-direction: column;
  gap: 20px;
  list-style: none;
  margin-top: 96px;

  svg {
    fill: $background-gray-400;
    min-width: 20px;
  }

  li a:hover {
    svg {
      fill: white;
    }
  }
}
