@use "./scss/colors.scss" as *;

/* css reset */
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

ul[role="list"],
ol[role="list"] {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img,
picture,
svg {
  display: block;
  max-width: 100%;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
    transition-duration: 0.01ms !important;
  }
}

/* Set defaults */

body {
  color: $text-gray-100;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  line-height: 1.5;
  text-rendering: optimizeSpeed;
}

h1 {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 10px;
}

p {
  margin-bottom: 12px;
}

a {
  color: $text-pink-200;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
