/* ---- Colors ---- */
$red-300: #ff0808;
$gray-400: #ffffff40;

/* ---- Backgrounds ---- */
$background-red-300: $red-300;

$background-gray-400: $gray-400;

$background-charcoal-300: #464646;
$background-charcoal-400: #343434;
$background-charcoal-600: #272727;

/* ---- Text ---- */
$text-gray-100: #c7c7c7;
$text-gray-400: $gray-400;

$text-pink-200: #ff9090;
$text-pink-600: #fd3f3f;

$text-red-300: $red-300;
