@use "../../scss/colors.scss" as *;

article {
  padding-bottom: 20px;
}

.time-line-container {
  .time-line {
    padding: 10px 20px 10px 40px;
    position: relative;

    &::before {
      background-color: $background-red-300;
      bottom: 0;
      content: "";
      left: 7px;
      position: absolute;
      top: 0;
      width: 2px;
    }

    .job-content {
      padding: 20px 30px;
      background-color: $background-charcoal-300;
      position: relative;
      border-radius: 6px;
      margin-bottom: 10px;

      ::before {
        border: 10px solid transparent;
        border-left: 0;
        border-right-color: $background-charcoal-300;
        content: "";
        left: -10px;
        position: absolute;
        top: 10%;
      }

      ::after {
        background: $background-charcoal-400;
        border: 4px solid $background-red-300;
        border-radius: 50%;
        content: "";
        height: 16px;
        left: -40px;
        position: absolute;
        top: 10%;
        width: 16px;
      }
    }
  }
}
